<template>
  <div>
    <b>Proformas:</b>
    <b-table small :fields="fields" :items="salesOrders" responsive="sm">
      <!-- A virtual column -->
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(orderID)="data">
        {{ data.item.orderNumber }}

      </template>

      <template #cell(issuedDate)="data">
        {{ dayjs(data.value).format("DD/MM/YYYY hh:mm:ss") }}
      </template>

      <template #cell(deliveryDate)="data">
        {{ dayjs(data.value).format("DD/MM/YYYY hh:mm:ss") }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(Popularity)="data">
        <b-progress :value="data.value.value" max="100" :variant="data.value.variant" striped />
      </template>

      <template #cell(order_status)="data">
        <b-badge pill :variant="data.value.variant">
          {{ data.value.status }}
        </b-badge>
      </template>

      <!-- A virtual composite column -->
      <template #cell(price)="data">
        {{ "$" + data.value }}
      </template>
    </b-table>
    <br />
    <b>Push Orders:</b>
    <b-table small :fields="fieldsNew" :items="pushOrders" responsive="sm">
      <!-- A virtual column -->
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(orderID)="data">
        {{ data.item.orderNumber }}
      </template>

      <template #cell(issuedDate)="data">
        {{ dayjs(data.value).format("DD/MM/YYYY hh:mm:ss") }}
      </template>

      <template #cell(deliveryDate)="data">
        {{ dayjs(data.value).format("DD/MM/YYYY hh:mm:ss") }}
      </template>

      <template #cell(orderComment)="data">
        <span v-if="!data.item.orderComment"> - </span>
        <span v-else-if="data.item.orderComment"> {{ data.item.orderComment }} </span>
      </template>

      <!-- A custom formatted column -->
      <template #cell(Popularity)="data">
        <b-progress :value="data.value.value" max="100" :variant="data.value.variant" striped />
      </template>

      <template #cell(order_status)="data">
        <b-badge pill :variant="data.value.variant">
          {{ data.value.status }}
        </b-badge>
      </template>

      <!-- A virtual composite column -->
      <template #cell(price)="data">
        {{ "$" + data.value }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BProgress, BBadge } from "bootstrap-vue";
import dayjs from "dayjs";

export default {
  components: {
    BTable,
    BProgress,
    BBadge,
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        "index",
        // A column that needs custom formatting
        { key: "orderID", label: "ORDER ID" },
        "issuedDate",
        // A regular column
        { key: "deliveryDate", label: "Delivery Date" },
        { key: "quantity", label: "Quantity" },

        // A regular column

        // A virtual column made up from two fields
      ],
      fieldsNew: [
        // A virtual column that doesn't exist in items
        "index",
        // A column that needs custom formatting
        { key: "orderID", label: "ORDER ID" },
        "issuedDate",
        // A regular column
        { key: "deliveryDate", label: "Delivery Date" },
        { key: "quantity", label: "Quantity" },
        { key: "orderComment", label: "Comment" },
        // A regular column

        // A virtual column made up from two fields
      ],
      dayjs,
    };
  },



  props: {
    salesOrders: {
      type: Array,
      default: () => [],
    },
    pushOrders: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
