<template>
  <div>
    <b-table small :fields="fields" :items="data.operationSteps" responsive="sm">
      <!-- A virtual column -->
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(startDate)="data">
        {{dayjs(data.value).format("DD/MM/YYYY hh:mm:ss")}}
      </template>
      <template #cell(endDate)="data">
        {{dayjs(data.value).format("DD/MM/YYYY hh:mm:ss")}}
      </template>
      <!-- A custom formatted column -->
      <template #cell(Popularity)="data">
        <b-progress
          :value="data.value.value"
          max="100"
          :variant="data.value.variant"
          striped
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable,
  BProgress,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BFormDatepicker,
} from "bootstrap-vue";
import dayjs from "dayjs"

export default {
  components: {
    BTable,
    BFormInput,
    BProgress,
    BFormDatepicker,
    BDropdownItem,
    BDropdown,
    BBadge,
  },

  props: {
    data: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      buttonTitle: "Accepted",
      fields: [
        // A virtual column that doesn't exist in items
        "id",

        { key: "name", label: "Operation Steps" },
        // A regular column
        { key: "workStation", label: "Workstations" },

        // A regular column
        { key: "startDate", label: "Started Date" },
        { key: "endDate", label: "End Date" },

        // A virtual column made up from two fields
      ],
      dayjs
    };
  },
};
</script>
