<template>
    <div>
        <b-table small :fields="fields" :items="data.qualityControl" responsive="sm">
            <!-- A virtual column -->
            <template #cell(#)="data">
                {{ data.index + 1 }}
            </template>

            <!-- A custom formatted column -->
            <template #cell(name)="data">
                {{ data.value.first }}
            </template>

            <!-- A custom formatted column -->
            <template #cell(Popularity)="data">
                <b-progress :value="data.value.value" max="100" :variant="data.value.variant" striped />
            </template>

            <template #cell(reading1)="data">
                <b-form-input id="reading1" type="number" :value="data.value" @change="readingOneUpdated(data.item.id, $event)" />                    
            </template>

            <template #cell(reading2)="data">
                <b-form-input id="reading2" type="number" :value="data.value" @change="readingTwoUpdated(data.item.id, $event)" />                    
            </template>

            <template #cell(status)="data">
              
                <b-form-select v-model="data.item.status" :options="optionQC" @change="readingQc(data.item.id, $event)" />
            </template>

            <template #cell(dateStarted)="data">
                <b-form-input>
                    {{ data.value }}
                </b-form-input>
            </template>
            <!-- A virtual composite column -->
            <template #cell(expectedDate)="data">
                <b-form-input>
                    {{ data.value }}
                </b-form-input>
            </template>
        </b-table>
    </div>
</template>
  
<script>
import { BTable, BProgress, BBadge, BDropdown, BDropdownItem, BFormInput, BFormSelect } from 'bootstrap-vue'

export default {
    components: {
        BTable,
        BFormInput,
        BProgress,
        BDropdownItem,
        BDropdown,
        BBadge,
        BFormSelect,
    },
    data() {
        return {
            optionQC: [
           
                { value: '0', text: 'Rejected' },
                { value: '1', text: 'Accepted' },
                
            ],
            buttonTitle: 'Accepted',
            fields: [
                // A virtual column that doesn't exist in items
                '#',
                // A column that needs custom formatting
                { key: 'operationSteps', label: 'Operation Step' },
                { key: 'qualityName', label: 'Quality Name' },
                
                // A regular column
                { key: 'minValue', label: 'Minimum Value' },
                { key: 'maxValue', label: 'Maximum Value' },
                // A regular column
                { key: 'reading1', label: 'Reading 1' },
                { key: 'reading2', label: 'Reading 2' },
                { key: 'status', label: 'Quality Status' },

                // A virtual column made up from two fields

            ],
          
        }
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        readingQc(id, value) {
            this.data.qualityControl.forEach(obj => {
                if(obj.id === id) {
                    obj.status = value;
                }
            });
        },
        readingOneUpdated(id, value) {
            this.data.qualityControl.forEach(obj => {
                if(obj.id === id) {
                    obj.reading1 = value;
                }
            });
        },
        readingTwoUpdated(id, value) {
            this.data.qualityControl.forEach(obj => {
                if(obj.id === id) {
                    obj.reading2 = value;
                }
            });
        }
    }
}
</script>