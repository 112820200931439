<template>
  <b-form @submit.prevent>
    <b-row>
      <b-col md="12">
        <b-form-group label="Invoice Amount" label-for="Operation-name">
          <b-form-input id="Operation-name" type="number" v-model="data.invoiceAmount" />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Planned Total BOM Cost" label-for="Operation-name">
          <b-form-input id="Operation-name" type="number"
            placeholder="This is fetched as per the BOM and the Operations defined in it" v-model="data.plannedCost"
            disabled />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Actual Total BOM Cost" label-for="Operation-name">
          <b-form-input id="Operation-name" type="number"
            placeholder="This is fetched from the Job Cards based on the Operations run on the Items."
            v-model="data.actualCost" disabled />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Labour Cost" label-for="labour-cost">
          <b-form-input id="labour-cost" type="number" v-model="data.labourCost" />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Transport/Logistics Cost" label-for="transport-cost">
          <b-form-input id="transport-cost" type="number" v-model="data.transportCost" />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Any Other Additional Operating Cost" label-for="Operation-name">
          <b-form-input id="Operation-name" type="number" v-model="data.additionalCost" />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Total Operating Cost" label-for="Operation-name">
          <b-form-input id="Operation-name" type="number"
            placeholder="This is calculated as Actual Operating Cost + Additional Operating Cost."
            v-model="data.totalOperationCost" disabled />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  watch: {
    'data.actualCost': 'calculateTotalOperationCost',
    'data.labourCost': 'calculateTotalOperationCost',
    'data.transportCost': 'calculateTotalOperationCost',
    'data.additionalCost': 'calculateTotalOperationCost',
    
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    calculateTotalOperationCost() {
      let sumOfOperationSteps = 0;
      this.data.operationSteps.forEach(step => {
        sumOfOperationSteps += step.totalCost; 
      });
      this.data.totalOperationCost = parseFloat(this.data.actualCost) +
        parseFloat(this.data.labourCost) +
        parseFloat(this.data.transportCost) +
        parseFloat(this.data.additionalCost) +
        sumOfOperationSteps; 
    },
  },
  created() {
    this.calculateTotalOperationCost(); 
  },

};
</script>
