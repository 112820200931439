<template>
  <div>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">

      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col></b-row>

    <b-table :items="logs" :fields="fields" striped responsive :per-page="perPage" :current-page="currentPage"
      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
      :filter-included-fields="filterOn" @filtered="onFiltered">
      <template #cell(show_details)="row">
        <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
        </b-form-checkbox>
      </template>

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col md="12" class="mb-1" v-if="row.item.oldValue == null">
              <strong>Old Values: </strong>No Old Values
            </b-col>
            <b-col md="12" class="mb-1" v-if="row.item.oldValue !== null">
              <strong>Old Values: </strong>
              <b-table :items="beautifyJson(row.item.oldValue)" :fields="['key', 'value']" bordered></b-table>
            </b-col>
            <b-col md="12" class="mb-1">
              <strong>New Values: </strong>
              <b-table :items="beautifyJson(row.item.newValue)" :fields="['key', 'value']" bordered></b-table>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </b-table>
    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
      class="my-0" />
  </div>
</template>
  
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BAvatar, BBadge, BPagination, BInputGroupAppend, BInputGroup, BFormSelect, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import api from "@/api/api-v2-config";
export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormGroup,
    BFormInput,
  },
  props: {
    informationData: {
      type: Array,
      default: () => { },
    },
  },
  data() {
    return {
      fields: [
        'show_details',
        "tenantID",
        { key: "createdOn", sortable: true }
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      logs: [],
      token: localStorage.getItem("accessToken"),
      id : this.$route.params.id,
    }
  },

  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },

  },
  mounted() {

    this.getLogs();
  },
  methods: {
    onFiltered(filteredItems) {
   
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    async getLogs() {
      let conf = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      };
      await api
        .get(`jobcards/historylog/${this.id}`, conf)

        .then((response) => {
          this.logs = response.data.result;
          this.totalRows = this.logs.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    beautifyJson(jsonData) {
      if (typeof jsonData === 'string') {
        try {
          jsonData = JSON.parse(jsonData);
        } catch (e) {
          console.error('Unable to parse JSON data', e);
          return [];
        }
      }

      if (jsonData && typeof jsonData === 'object') {
        return Object.keys(jsonData).map(key => {
          return { key: key, value: jsonData[key] };
        });
      }
      return [];
    },

  },


}
</script>