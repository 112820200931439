<template>
  <div>
    <b-row>
      
      <b-col lg="9" md="12" sm="12">
        <b-row>
          <b-col cols="12">
  
            <!--Company Name-->
            <b-form-group label="Product Name" label-for="h-Order-name" label-cols-md="2">
              <b-form-input id="h-Order-name" placeholder="product 1" readonly v-model="JobCard.productName" />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <!--Address-->
            <b-form-group label="Start Date" label-for="h-Owner-name" label-cols-md="2">
              <b-form-datepicker id="startDatePicker" class="mb-1" v-model="job.startDate" @input="startDate()"  :min="new Date()"   :disabled="JobCard.status === 1"  />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <!--City-->
            <b-form-group label="Finish Date" label-for="h-Owner-name" label-cols-md="2">
              <b-form-datepicker id="endDatePicker" class="mb-1" v-model="JobCard.endDate" disabled />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <!--City-->
            <b-form-group label="Delivery Date" label-for="h-Owner-name" label-cols-md="2">
              <b-form-datepicker id="deliveryDatePicker" class="mb-1" v-model="JobCard.deliveryDate" :min="new Date()" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <!--Address-->
            <b-form-group label="Status" label-for="h-Owner-name" label-cols-md="2">
              <b-form-select v-model="job.status" :options="options" @change="callChangeStatus()" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="3" md="12" sm="12" class="d-flex align-items-center justify-content-center">
        <b-card class="text-center p-3" bg-variant="primary" body-text-variant="info">
          <h2 class="text-info">Quantity In Production: </h2>
          <h1 class="text-info">{{ JobCard.quantityInProduction }}</h1>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BForm,
  BFormSelect,
  BDropdownItem,
  BContainer,
  BDropdown,
  BCard,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import dayjs from "dayjs"
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BButton,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BFormDatepicker,
    BContainer,
  },

  computed: {
  
    ...mapGetters("jobcardModule", {
      jobcardList: "jobcards",
      loading: "loading",
    }),
  },
  data() {
    return {
      dayjs,
      config: {
        wrap: {
          wrap: true,

          minDate: new Date()
        }

      },
      
      job: [],
      newDate: "",
      options: [
        // { value: null, text: "Select Status" },
        { value: 0, text: "Not Started" },
        { value: 1, text: "Started" },
        { value: 2, text: "Completed" },
        { value: 3, text: "Cancelled" },
        { value: 4, text: "Blocked" },
      ],
    };
  },

  methods: {
    ...mapActions("jobcardModule", ["getJobcardListAction", "updateJobcardStatusAction"]),

    callChangeStatus() {
      this.$emit("status", this.job.status);
    },
    startDate(){
      this.$emit("startDate", this.job.startDate);
    },
  },
  


  async mounted() {
    await this.getJobcardListAction()
      .then(() => {
       this.jobcardList.forEach(element => {
          if(element.id == this.$route.params.id){
            this.job = element
            this.$emit("status", this.job.status);
            this.$emit("startDate", this.job.startDate);
          }
       });
      })
    
  },
  props: {
    JobCard: {
      type: Object,
      required: true
    },
  },
};
</script>
