<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
<template>
  <div>
    <div>
      <b-form ref="form" @change="formChanged()" class="repeater-form" v-if="JobCard">
        <!-- Row Loop -->
        <b-row v-for="(item, index) in JobCard.bom" :id="index.toString()" :key="index" ref="row">
          <!-- Item Name -->
          <b-col md="3">
            <b-form-group label="Raw Material / Work In Progress" label-for="Operation-name">
              <b-form-input id="cost" placeholder="" v-model="item.rawMaterial" />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-form-group label="UOM" label-for="cost">
              <b-form-input id="cost" placeholder="" v-model="item.uom" />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Qty Required" label-for="cost">
              <b-form-input id="cost" type="number" placeholder="" :value="item.quantity"
                @change="itemChanged(item, $event)" />
            </b-form-group>
          </b-col>
          <!-- Profession -->
          <b-col lg="2" md="2">
            <b-form-group label="Total Cost" label-for="price">
              <b-form-input id="pzrice" value="32$" v-model="item.totalCost" disabled />
            </b-form-group>
          </b-col>
          <!-- Remove Button -->
          <b-col lg="2" md="1" class="mb-50">
            <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2"
              @click="removeItem(index, item.id)">
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col lg="2" md="1" class="mb-50">
            <b-button variant="primary" class="mt-0 mt-md-2" :to="createPurchase(item.materialId, item.quantity)">
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Create PO</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BFormTextarea,
  BCard,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BForm,
    BRow,
    BCard,
    vSelect,
    BCol,
    BButton,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      id: this.$route.params.id,
      selectedBom: null,
      nextTodoId: 2,
      removedBom: [],
    };
  },
  props: {
    JobCard: {
      type: Object,
      default: () => ({}),
    },
  },


  methods: {
    createPurchase(id, quantity) {
      return "/purchaseorders/add/" + id + "/" + quantity;
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index, id) {
      let removedItemCost = this.JobCard.bom[index].totalCost || 0;
      this.JobCard.bom.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
      this.removedBom.push(id);
      this.JobCard.actualCost -= removedItemCost;
      console.log(this.JobCard)
      this.formChanged();
    },
    initTrHeight() {
      this.trSetHeight(null);
      if (this.$refs.form) {
        this.$nextTick(() => {
          this.trSetHeight(this.$refs.form.scrollHeight);
        });
      }
    },
    itemChanged: function (item, newVal) {
      let oldQty = item.quantity;
      let newQty = newVal;
      let unitCost = item.totalCost / oldQty;
      let oldTotalCost = item.totalCost; // Save the old total cost before updating

      item.quantity = newQty;
      item.totalCost = item.quantity * unitCost;

      let differenceInTotalCost = item.totalCost - oldTotalCost; // Calculate the difference in total cost
      this.JobCard.actualCost += differenceInTotalCost; // Apply the difference to the actual cost

      console.log(this.JobCard.actualCost);
    },
    formChanged() {
      this.$emit("onchange", this.removedBom);
    },
  },
};
</script>
