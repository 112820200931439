<template>
  <div class="container mt-5">

    <div class="row mt-5">
      <div class="col-lg-2 col-md-2 col-sm-12">

        <div class="p-2 alert alert-secondary">
          <h3>Back Log</h3>

          <!-- Backlog draggable component. Pass arrBackLog to list prop -->
          <draggable class="list-group kanban-column" :list="arrBackLog" group="tasks"
            @change="statusUpdated(0, arrBackLog)">
            <div class="list-group-item" v-for="element in arrBackLog" :key="element.id">
              {{ element.name }}
              <div>
                <b-form-textarea id="textarea-default" placeholder="Comment" rows="3" v-model="element.comment"
                  @blur="updateComment(element)" />
              </div>
            </div>
          </draggable>
        </div>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-12">
        <div class="p-2 alert alert-primary">
          <h3>Started</h3>
          <!-- In Progress draggable component. Pass arrInProgress to list prop -->
          <draggable class="list-group kanban-column" :list="arrInProgress" group="tasks"
            @change="statusUpdated(1, arrInProgress)">
            <div class="list-group-item" v-for="element in arrInProgress" :key="element.name">
              {{ element.name }}
              <div>
                <b-form-textarea id="textarea-default" placeholder="Comment" rows="3" v-model="element.comment"
                  @blur="updateComment(element)" />
              </div>
            </div>
          </draggable>
        </div>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-12">
        <div class="p-2 alert alert-warning">
          <h3>On Hold</h3>
          <!-- Testing draggable component. Pass arrTested to list prop -->
          <draggable class="list-group kanban-column" :list="arrHold" group="tasks" @change="statusUpdated(2, arrHold)">
            <div class="list-group-item" v-for="element in arrHold" :key="element.name">
              {{ element.name }}
              <div>
                <b-form-textarea id="textarea-default" placeholder="Comment" rows="3" v-model="element.comment"
                  @blur="updateComment(element)" />
              </div>
            </div>
          </draggable>
        </div>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-12">
        <div class="p-2 alert alert-info">
          <h3>WIP 1</h3>
          <!-- Done draggable component. Pass arrDone to list prop -->
          <draggable class="list-group kanban-column" :list="arrInWIP" group="tasks" @change="statusUpdated(3, arrInWIP)">
            <div class="list-group-item" v-for="element in arrInWIP" :key="element.name">
              {{ element.name }}
              <div>
                <b-form-textarea id="textarea-default" placeholder="Comment" rows="3" v-model="element.comment"
                  @blur="updateComment(element)" />
              </div>
            </div>
          </draggable>
        </div>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-12">
        <div class="p-2 alert alert-info">
          <h3>WIP 2</h3>
          <!-- Done draggable component. Pass arrDone to list prop -->
          <draggable class="list-group kanban-column" :list="arrInWIP2" group="tasks"
            @change="statusUpdated(4, arrInWIP2)">
            <div class="list-group-item" v-for="element in arrInWIP2" :key="element.name">
              {{ element.name }}
              <div>
                <b-form-textarea id="textarea-default" placeholder="Comment" rows="3" v-model="element.comment"
                  @blur="updateComment(element)" />
              </div>
            </div>
          </draggable>
        </div>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-12">
        <div class="p-2 alert alert-success">
          <h3>Completed</h3>
          <!-- Done draggable component. Pass arrDone to list prop -->
          <draggable class="list-group kanban-column" :list="arrDone" group="tasks" @change="statusUpdated(5, arrDone)">
            <div class="list-group-item" v-for="element in arrDone" :key="element.name">
              {{ element.name }}
              <div>
                <b-form-textarea id="textarea-default" placeholder="Comment" rows="3" v-model="element.comment"
                  @blur="updateComment(element)" />
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import draggable
import draggable from "vuedraggable";
import { BFormTextarea } from "bootstrap-vue";
export default {
  name: "kanban-board",
  components: {
    //import draggable as a component
    draggable,
    BFormTextarea
  },
  props: {
    data: {
      type: Object,
      default: () => [],
    },
    arrBackLog: {
      type: Array,
      required: true
    },
    arrInProgress: {
      type: Array,
      required: true
    },
    arrHold: {
      type: Array,
      required: true
    },
    arrInWIP: {
      type: Array,
      required: true
    },
    arrInWIP2: {
      type: Array,
      required: true
    },
    arrDone: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      // for new tasks
      newTask: ""
    };
  },

  mounted() {
    this.initializeComments(this.arrBackLog);
    this.initializeComments(this.arrInProgress);
    this.initializeComments(this.arrHold);
    this.initializeComments(this.arrInWIP);
    this.initializeComments(this.arrInWIP2);
    this.initializeComments(this.arrDone);
  },
  
  methods: {
    //add new tasks method
    initializeComments(taskArray) {
      taskArray.forEach(task => {
        if (!task.comment || task.comment.trim() === '') {
          task.comment = '-';
        }
      });
    },
    add: function () {
      if (this.newTask) {
        this.arrBackLog.push({ name: this.newTask });
        this.newTask = "";
      }
    },
    updateComment(element) {
      if (!element.comment || element.comment.trim() === '') {
        this.$swal({
          icon: 'warning',
          title: 'Warning',
          text: 'Comment cannot be empty, adding a default comment',
        });
        element.comment = '-';
        return;
      }
      let vm = this;
      let step = vm.data.operationSteps.filter(obj => {
        return obj.id === element.id;
      });
      if (step.length > 0) {
        step[0].comment = element.comment;
      }
    },


    statusUpdated(status, arr) {
      let vm = this;
      arr.forEach(sts => {
        let step = vm.data.operationSteps.filter(obj => {
          return obj.id === sts.id;
        });
        if (step.length > 0) {
          step[0].status = status;
        }
      });
    }
  }
};
</script>

<style>
/* light stylings for the kanban columns */
.kanban-column {
  min-height: 300px;
}
</style>
