<template>
  <div>
    <b-container class="d-flex justify-content-center py-2">
      <OnlyView :JobCard="JobCard" @onchange="bomChanged" />
    </b-container>      
    <b-container class="d-flex justify-content-center py-2">
      <AddBOM :JobCard="JobCard" @onchange="addBomChanged" />
    </b-container> 
  
  </div>
</template>

<script>
import { BTable, BProgress, BBadge, BContainer } from "bootstrap-vue";
import AddBOM from "./AddBOM";
import OnlyView from "./OnlyView";

export default {
  components: {
    BTable,
    BContainer,
    AddBOM,
    BProgress,
    OnlyView,
    BBadge,
  },
  data() {
    return {
      addBom: [],
      removeBom: []
    }
  },
  props: {
    JobCard: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    bomChanged(removedBom) {
      this.removeBom = removedBom;
      this.$emit('onchange', this.addBom, this.removeBom);
    },
    addBomChanged(addedBom) {                      
      this.addBom = addedBom;              
      this.$emit('onchange', this.addBom, this.removeBom);
    },
  }
};
</script>
