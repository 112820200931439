<template>
  <div class="main">
    <div class="d-flex justify-content-center" v-if="loadingSpinner">
      <b-spinner
        variant="success"
        label="Spinning"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div v-if="apiError" class="alert alert-danger p-2" role="alert">
      An error occurred while fetching data. Please try again later.
    </div>

    <div v-if="!loadingSpinner && !apiError">
      <app-collapse accordion>
        <app-collapse-item title="Summary">
          <Summary :JobCard="jobId" @status="getStatus" @startDate="getDate" />
          <SalesOrders
            :salesOrders="jobId.salesOrderDetail"
            :pushOrders="jobId.pushOrderDetail"
          />
        </app-collapse-item>
        <app-collapse-item title="Bill Of Materials">
          <BomTb :JobCard="jobId" @onchange="bomChanged" />
        </app-collapse-item>
        <app-collapse-item title="Operation Steps">
          <!-- <Operations :data="jobId" /> -->
          <b-tabs card>
            <b-tab title="Kanban">
              <Kanban
            v-if="jobId.operationSteps"
            :data="jobId"
            :arrBackLog="getFilteredOperations(0)"
            :arrInProgress="getFilteredOperations(1)"
            :arrHold="getFilteredOperations(2)"
            :arrInWIP="getFilteredOperations(3)"
            :arrInWIP2="getFilteredOperations(4)"
            :arrDone="getFilteredOperations(5)"
          />
            </b-tab>
            <b-tab title="Operation Log">
              <OperationLogs/>
            </b-tab>
          </b-tabs>
         
        </app-collapse-item>

        <app-collapse-item title="Cost">
          <TotalCost :data="jobId" />
        </app-collapse-item>
        <app-collapse-item title="Quality Control">
          <QualityCheck :data="jobId" />
        </app-collapse-item>
      </app-collapse>
      <b-container class="d-flex justify-content-center py-2">
        <b-row>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="updateJobCard"
              v-if="!loading"
            >
              <span>Update Job Card</span>
            </b-button>
            <div class="d-flex justify-content-center">
              <b-spinner large v-if="loading" class="mx-2" />
              <span v-if="loading">Loading...</span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
  BContainer,
  BTabs,
  BTab,
} from "bootstrap-vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import SalesOrders from "./components/SalesOrders";
import BomTb from "./components/BomTb";
import Kanban from "./components/Kanban.vue";
import Summary from "./components/Summary.vue";
import Operations from "./components/Operations.vue";
import TotalCost from "./components/TotalCost.vue";
import QualityCheck from "./components/QualityCheck.vue";
import { mapActions, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import OperationLogs from "./components/OperationLogs.vue";
export default {
  components: {
    BTable,
    QualityCheck,
    BomTb,
    AppCollapse,
    AppCollapseItem,
    SalesOrders,
    Operations,
    BAvatar,
    TotalCost,
    Kanban,
    BBadge,
    Summary,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BContainer,
    BTabs,
    BTab,OperationLogs
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters("jobcardModule", {
      jobId: "jobId",
      loading: "loading",
    }),
  },
  data() {
    return {
      id: this.$route.params.id,
      addedBom: [],
      loadingSpinner: true,
      removedBom: [],
      newStatus: 0,
      start: null,
      apiError: false,
      additionalCost: 0,
    };
  },

  methods: {
    getFilteredOperations(status) {
      let arr = this.jobId.operationSteps.filter((obj) => {
        return obj.status === status;
      });
      return arr;
    },
    showSuccess() {
      this.$swal({
        title: "Job Card Updated!",
        text: "You have successfully updated a Job Card !",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getStatus(data) {
      this.newStatus = data;
    },
    getDate(data) {
      this.start = data;
    },
    // error
    errorAdd(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data);
      this.$swal({
        title: "Error!",
        html: msg ?? " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(data) {
      let msg = null;
      if (data.errors) {
        let errors = data.errors;
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (
            Object.prototype.hasOwnProperty.call(errors, prop) &&
            prop !== "request"
          ) {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
      } else if (data.message) {
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        msg += data.message;
        msg += "</div>";
      }
      return msg;
    },
    ...mapActions("jobcardModule", [
      "getJobcardIdAction",
      "updateJobcardAction",
    ]),
    bomChanged(addedBom, removedBom) {
      this.addedBom = addedBom;
      this.removedBom = removedBom;
    },

    updateJobCard() {
      console.log(this.jobId.transportCost);
      let payload = {
        id: this.jobId.id,
        status: this.newStatus,
        deliveryDate: this.jobId.deliveryDate,
        startDate: this.start,
        warehouseId: this.jobId.warehouseId,
        endDate: this.jobId.endDate,
        plannedCost: this.jobId.plannedCost,
        actualCost: this.jobId.actualCost,
        additionalCost: parseFloat(this.jobId.additionalCost)
          ? parseFloat(this.jobId.additionalCost)
          : 0,
        totalCost: this.jobId.totalOperationCost,
        bomDetails: [],
        removeBOMObj: [],
        jobCardOperationDetails: [],
        qualityControlDetails: [],
        labourCost: parseFloat(this.jobId.labourCost)
          ? parseFloat(this.jobId.labourCost)
          : 0,
        transportCost: parseFloat(this.jobId.transportCost)
          ? parseFloat(this.jobId.transportCost)
          : 0,
        invoiceAmount: parseFloat(this.jobId.invoiceAmount)
          ? parseFloat(this.jobId.invoiceAmount)
          : 0,
      };

      let vm = this;
      let itemId = null;
      vm.jobId.bom.forEach((obj) => {
        if (itemId === null) {
          itemId = obj.itemId;
        }
        payload.bomDetails.push({
          id: obj.id,
          itemId: obj.itemId,
          uomid: obj.uomId,
          quantity: obj.quantity,
          totalcost: obj.totalCost,
        });
      });
      vm.addedBom.forEach((obj) => {
        payload.bomDetails.push({
          itemId: itemId,
          materialId: obj.id,
          uomid: obj.uom,
          quantity: obj.quantity,
          totalcost: obj.totalCost,
        });
      });
      vm.removedBom.forEach((obj) => {
        payload.removeBOMObj.push(obj);
      });
      vm.jobId.operationSteps.forEach((obj) => {
        payload.jobCardOperationDetails.push({
          id: obj.id,
          status: obj.status,
          comments: obj.comment,
        });
      });
      vm.jobId.qualityControl.forEach((obj) => {
        payload.qualityControlDetails.push({
          id: obj.id,
          minValue: obj.minValue,
          maxValue: obj.maxValue,
          reading1: obj.reading1,
          reading2: obj.reading2,
          status: obj.status,
        });
      });
      console.log("upon updating job card's final payload: ", payload);
      this.updateJobcardAction(payload)
        .then(() => {
          this.showSuccess();
          window.reload();
        })
        .catch((ex) => {
          this.errorAdd(ex.response.data);
        });
    },
  },

  async mounted() {
    setTimeout(() => {
      this.loadingSpinner = false;
    }, 5000);
    await this.getJobcardIdAction(this.id)
      .then(() => {})
      .catch((ex) => {
        this.jobId = null;
        this.apiError = true;
      });
  },
};
</script>
